import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>FINPORTO</h1>  {/* This adds the heading */}
        <p>UNDER CONSTRUCTION</p>  {/* This adds the paragraph */}
      </header>
    </div>
  );
}

export default App;